import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { Species } from '../models/species.model';
import { BaseDataService } from './base-data.service';

@Injectable({
  providedIn: 'root'
})
export class SpeciesService extends BaseDataService {

 
  constructor(private http: HttpClient) {
    super();
  }

  getAll(): Observable<Species[]> {
    return this.http.get<Species[]>(`${this.apiUrl}/species`);
  }

  getSingle(id: Guid): Observable<Species> {
    return this.http.get<Species>(`${this.apiUrl}/species/${id}`);
  }

  save(model: Species): Observable<Species> {
    if (model.id) {
      return this.http.post<Species>(`${this.apiUrl}/species`, model);
    } else {
      return this.http.put<Species>(`${this.apiUrl}/species`, model);
    }
  }

  delete(id: Guid): Observable<any> {
    return this.http.delete(`${this.apiUrl}/species/${id}`);
  }
}
