import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseDataService {

  protected apiUrl = `${environment.apiUrl}/api`;
  constructor() { }
}
