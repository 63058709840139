import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from '../../models/order.model';
import { OrderDataService } from '../../services/order.service';
import notify from 'devextreme/ui/notify';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-send-aims-button',
  templateUrl: './send-aims-button.component.html',
  styleUrls: ['./send-aims-button.component.scss']
})
export class SendAimsButtonComponent implements OnInit {

  allowAimsSubmit: boolean;
  allowedStatuses: string[] = ['AIMS_AUTH_ERROR', 'NEW', 'DATAMARS_DONE'];

  caption: string = 'Send to AIMS';

  private _selectedOrders: Array<Order> = [];
  @Input('selectedOrders')
  get selectedOrders(): Array<Order> { return this._selectedOrders; }
  set selectedOrders(orders: Array<Order>) { this._selectedOrders = orders; this.selectedOrdersChanged(); }

  @Output() aimsSubmitted = new EventEmitter<boolean>();

  constructor(private dataService: OrderDataService) { }

  ngOnInit(): void {
  }

  selectedOrdersChanged() {

    this.allowAimsSubmit = this.selectedOrders && this.selectedOrders.length > 0
      && this.selectedOrders.filter(o => this.allowedStatuses.indexOf(o.statusText) > -1).length
      === this.selectedOrders.length


  }

  executeAimsRequest() {

    let task: any;
    let ids: Array<Guid>;


    ids = this.selectedOrders
      .filter(o => this.allowedStatuses.indexOf(o.statusText) > -1)
      .map(o => o.id);

    task = this.dataService.submitAimsRequest(ids);

    task.subscribe(x => {
      this.aimsSubmitted.emit(x);
    },
      e => {

        notify('Task finished with errors', 'error');
        this.aimsSubmitted.emit(false);
      });
  }
}
