import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { AIMsError } from 'src/app/shared/models/aims-error.model';
import { AimsErrorService } from 'src/app/shared/services/aims-error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  record = new AIMsError();
  formValid = false;

  private sub: Subscription;
  private dataSub: Subscription;
  constructor(private dataService: AimsErrorService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      const id = params.id;
      if (id) {
        this.loadData(Guid.parse(id));
      }
    });
  }


  onFieldDataChanged(e: any) {
    this.formValid = e.component.validate().isValid;

  }


  loadData(id: Guid) {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
    this.dataSub = this.dataService.getSingle(id)
      .subscribe(x => {
        this.record = x;
      });
  }

  saveRecord(e: AIMsError) {
    this.dataService.save(e).subscribe(x => {
      if (x) {
        this.router.navigate(['/aims/errors']);
      }
    });
  }


  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

}
