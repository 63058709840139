import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AIMsError } from 'src/app/shared/models/aims-error.model';
import { AimsErrorService } from 'src/app/shared/services/aims-error.service';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit {
  records: Array<AIMsError> = [];
  constructor(private dataService: AimsErrorService,
    private router: Router) { }

  ngOnInit(): void {
    this.dataService.getAll()
      .subscribe(x => {
        this.records = x.sort((a, b) => {
          if(a.code < b.code) { return -1; }
          if(a.code > b.code) { return 1; }
          return 0;
        });
      });
  }

  onInitNewRow(e) {
    this.router.navigate(['/aims/error']);
  }

  onRowRemoving(e: any) {
    e.data.active = false;
    const result = this.dataService.delete(e.data.id).toPromise();
    e.cancel = new Promise((resolve, reject) => {
      result.then((x) => {
        resolve(x);
      })
        .catch(() => reject());
    });
  }
}
