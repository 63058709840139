<ng-container *ngIf="isAuthorized(); else notAuthorized">
  <app-side-nav-inner-toolbar title="{{appInfo.title}}">
    <router-outlet></router-outlet>
    <app-footer>
      Copyright © {{appInfo.currentYear}} XTRACE SYSTEMS LIMITED
      <br />
      Powered By
      <a href="https://www.xtrace.app" target="-blank">
        <img src="https://www.xtrace.app/assets/images/logo2.png" height="10px" class="ml-1">
      </a>
    </app-footer>
  </app-side-nav-inner-toolbar>
</ng-container>

<ng-template #notAuthorized>
  <app-single-card>
    <router-outlet></router-outlet>
  </app-single-card>
</ng-template>