<h2 class="content-block">DATAMARS products</h2>
<div class="content-block">
    <div class="dx-card responsive-paddings">

        <div class="row">
            <div class="col-12 text-center">
                <dx-data-grid id="gridCustomers" [dataSource]="records" [allowColumnResizing]="true">
                    <dxo-editing [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false" mode="form">
                    </dxo-editing>
                    <dxo-export [enabled]="true" fileName="datamars-component-sets" [allowExportSelectedData]="true">
                    </dxo-export>
                    <dxo-paging [pageSize]="25"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                    <dxi-column type="buttons" width="60" css>
                        <dxi-button icon="copy" cssClass="btn w-100" [visible]="true" [onClick]="copyToClipboard">
                        </dxi-button>
                    </dxi-column>
                    <dxi-column dataField="id" width="280"></dxi-column>
                    <dxi-column dataField="name"></dxi-column>
                    <dxi-column dataField="description"></dxi-column>
                    <dxi-column dataField="enabled"></dxi-column>

                </dx-data-grid>
            </div>

        </div>
    </div>