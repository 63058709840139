<h2 class="content-block">Product &nbsp;<strong>{{record?.code}}</strong></h2>



<div class="content-block dx-card responsive-paddings">
    <div class="row">
        <div class="col-12 offset-lg-8 col-lg-2">
            <dx-button type="warning" text="cancel" class="btn w-100" routerLink="/data/products"> </dx-button>
        </div>
        <div class="col-12 col-lg-2">
            <dx-button type="default" text="save" class="btn w-100" [disabled]="!formValid"
                (onClick)="saveRecord(record)"> </dx-button>
        </div>
    </div>
    <dx-form [(formData)]="record" labelLocation="top" optionalMark="opt" [showOptionalMark]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)" validationGroup="productData" #productForm>

        <dxi-item itemType="group" [colCount]="3">
            <dxi-item dataField="code">
                <dxi-validation-rule type="required" message="Code is required">
                </dxi-validation-rule>

            </dxi-item>
            <dxi-item dataField="speciesId" editorType="dxSelectBox"
                [editorOptions]="{dataSource: species, displayExpr: 'code', valueExpr: 'id', searchEnabled: true}">
                <dxo-label text="Species"></dxo-label>
            </dxi-item>
            <dxi-item dataField="active" editorType="dxCheckBox">
            </dxi-item>

        </dxi-item>
        <dxi-item itemType="group" [colCount]="3">
            <dxi-item dataField="shortName">
                <dxi-validation-rule type="required" message="Short Name is required">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="price" editorType="dxNumberBox"></dxi-item>
            <dxi-item dataField="tagTypeId" editorType="dxNumberBox"></dxi-item>
        </dxi-item>

        <dxi-item dataField="name">
            <dxo-label text="Description"></dxo-label>
            <dxi-validation-rule type="required" message="Description is required">
            </dxi-validation-rule>

        </dxi-item>
        <dxi-item itemType="group" [colCount]="4">
            <dxi-item dataField="eCommerceRef">
                <dxo-label text="Woo Code"></dxo-label>
            </dxi-item>
            <dxi-item>

                <dxo-label text="DataMars Id"></dxo-label>
                <div *dxTemplate>
                    <dx-text-box [(value)]="record.dataMarsRef" (onValueChanged)="onFieldDataChanged($event)">
                        <dxi-button name="checkDataMars" [options]="datamarsButtonOptions" type="normal">
                        </dxi-button>
                        <dx-validator validationGroup="productData">
                            <dxi-validation-rule type="required" message="Datamars Code is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>

                </div>

            </dxi-item>
            <dxi-item dataField="requireTag" editorType="dxCheckBox">
                <dxo-label text="Order Line requires Tag Details"></dxo-label>
            </dxi-item>
            <dxi-item dataField="isSet" editorType="dxCheckBox">
                <dxo-label text="Product is a 'Set'"></dxo-label>
            </dxi-item>
        </dxi-item>

    </dx-form>
    <div class="row pt-4">
        <div class="col-12">
            <span [innerHTML]="datamarsSuccessMessage" class="text-success" *ngIf="datamarsSuccessMessage"></span>
            <span [innerHTML]="datamarsErrorMessage" class="text-danger" *ngIf="datamarsErrorMessage"></span>
        </div>
    </div>
</div>