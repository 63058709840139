<div class="row content-block">
    <div class="col-12">
        <h2>Species</h2>
    </div>

</div>
<div class="content-block dx-card responsive-paddings">
    <div class="row">
        <div class="col-12 text-center">
            <dx-data-grid id="speciesGrid" keyExpr="id" (onRowRemoving)="onRowRemoving($event)"
                (onInitNewRow)="onInitNewRow($event)" [dataSource]="species">

                <dxo-editing [allowUpdating]="false" [allowAdding]="true" [allowDeleting]="true" mode="form">
                </dxo-editing>
                <dxo-export [enabled]="true" fileName="Species" [allowExportSelectedData]="true"></dxo-export>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>

                <dxi-column caption="Code" cellTemplate="codeTemplate"></dxi-column>
                <dxi-column dataField="description"></dxi-column>
                <dxi-column dataField="deptSpeciesId" caption="AIMS Reference"></dxi-column>
                <dxi-column dataField="active" dataType="boolean"></dxi-column>

                <div *dxTemplate="let d of 'codeTemplate'">
                    <strong><a [routerLink]="['/data/species', d.data.id]">{{d.data.code }}</a></strong>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>