import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { DataMarsService } from 'src/app/shared/services/datamars.service';

@Component({
  selector: 'app-datamars-component-sets',
  templateUrl: './datamars-component-sets.component.html',
  styleUrls: ['./datamars-component-sets.component.scss']
})
export class DatamarsComponentSetsComponent implements OnInit {

  records: Array<any> = [];
  constructor(private datamarsService: DataMarsService) { }

  ngOnInit(): void {
    this.datamarsService.getComponentSets()
      .subscribe(x => {
        this.records = x;
      })
  }


  copyToClipboard(e) {
    const id = e.row.data.id;
    navigator.clipboard.writeText(id)
      .then(() => {
        notify(`${id} copied to clipboard`);
      })
      .catch(e => console.error(e))


  }

}
