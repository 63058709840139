import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Order } from "src/app/shared/models/order.model";
import { OrderDataService } from "src/app/shared/services/order.service";
import * as moment from "moment";

import { LOCALE_ID, NgModule } from "@angular/core";
import { Guid } from "guid-typescript";
import notify from "devextreme/ui/notify";

export class PreservedSettings {
  fromDate: Date;
  toDate: Date;
  pageIndex: number;
}

export class FilterOptions {
  id: string;
  text: string;
}
@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
  lastWooChecked: Date;
  fromDate: Date;
  toDate: Date;
  pageIndex: number = 0;

  selectedOrders: Array<Order> = [];
  @Output() selectedOrdersChange = new EventEmitter<Array<Order>>();
  records: Array<Order> = [];
  filteredRecords: Array<Order> = [];
  selectedIds: Array<Guid> = [];
  allowDatamarsSubmit: boolean;

  settings: PreservedSettings;

  filterOptions: FilterOptions[] = [
    { text: "AIMS ready", id: "aims" },
    { text: "DATAMARS ready", id: "datamars" },
    { text: "INTACT ready", id: "intact" },
  ];
  filter: string;

  constructor(
    private dataService: OrderDataService,
    private router: Router,
    @Inject(LOCALE_ID) public LOCALE_ID: string,
    private orderService: OrderDataService
  ) {
    this.showLog = this.showLog.bind(this);
  }

  ngOnInit(): void {
    this.settings = JSON.parse(localStorage.getItem("OrdersComponent"));
    if (this.settings) {
      this.fromDate = this.settings.fromDate;
      this.toDate = this.settings.toDate;
      this.pageIndex = this.settings.pageIndex;
    } else {
      this.fromDate = moment().add(-7, "day").toDate();
      this.toDate = new Date();
    }

    this.loadData();
  }

  resetSelection() {
    this.selectedIds = [];
    this.selectedOrders = [];
    this.selectedOrdersChange.emit(this.selectedOrders);
  }
  private loadData(): void {
    this.dataService.getAll(this.fromDate, this.toDate).subscribe((x) => {
      this.resetSelection();
      this.records = x;
      this.processFilter();
    });

    this.dataService.lastWoo().subscribe((x) => {
      this.lastWooChecked = x;
    });
  }

  onInitNewRow(e) {
    this.router.navigate(["/data/order"]);
  }

  onRowRemoving(e: any) {
    // e.data.active = false;
    // const result = this.dataService.delete(e.data.id).toPromise();
    // e.cancel = new Promise((resolve, reject) => {
    //   result.then((x) => {
    //     resolve();
    //   })
    //     .catch(() => reject());
    // });
  }

  onToolbarPreparing(e: any) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: "fromDate",
      },
      {
        location: "before",
        template: "toDate",
      },
      {
        location: "center",
        template: "filterButtons",
      }
    );
  }

  executeWooImport() {
    this.orderService.importWoo().subscribe(
      (x) => {
        this.loadData();
      },
      (e) => {
        if (e.status === 400) {
          notify(e.error, "info");
        } else {
          notify("Error while importing new orders", "error", 5000);

        }
        console.log(e);
      }
    );
  }

  saveOnScreenSelections() {
    this.settings = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      pageIndex: this.pageIndex,
    };
    localStorage.setItem("OrdersComponent", JSON.stringify(this.settings));
  }

  onDateSelectionChanged() {
    this.loadData();
    this.saveOnScreenSelections();
  }

  orderSelectionChanged() {
    this.selectedOrders = this.records.filter((o) =>
      this.selectedIds.find((id) => o.id == id)
    );
  }

  isLogIconVisible(e) {
    var rowItem: Order = e.row.data;
    return rowItem.statusText === "AIMS_AUTH_ERROR"; //add more later
  }

  showLog(e: any) {
    debugger;
    var order: Order = e.row.data;

    alert(order.orderNo);
  }

  aimsResponse(success: boolean) {
    this.loadData();
  }

  datamarsResponse(success: boolean) {
    this.loadData();
  }

  intactResponse(success: boolean) {
    this.loadData();
  }

  calculateCellValue(rowData): string {
    return rowData.code;
  }

  orderStatus(order: Order, statusGood: string[], statusBad: string[]): string {
    if (statusGood.find((s) => s === order.statusText)) return "text-success";
    if (statusBad.find((s) => s === order.statusText)) return "text-danger";
    return "text-default";
  }

  processFilter() {
    if (!this.filter) {
      this.filteredRecords = this.records;
    } else if (this.filter === "aims") {
      this.filteredRecords = this.records.filter(
        (r) => this.dataService.aimsReady.indexOf(r.statusText) > -1
      );
    } else if (this.filter === "datamars") {
      this.filteredRecords = this.records.filter(
        (r) => this.dataService.datamarsReady.indexOf(r.statusText) > -1
      );
    } else if (this.filter === "intact") {
      this.filteredRecords = this.records.filter(
        (r) => this.dataService.intactReady.indexOf(r.statusText) > -1
      );
    }
  }

  onFilterValueChanged(e) {
    this.resetSelection();
    this.processFilter();
  }
}
