import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { Order } from '../models/order.model';
import { BaseDataService } from './base-data.service';
import * as moment from 'moment';
import { AimsOrder, AimsOrderTag } from '../models/aims-order.model';


@Injectable({
  providedIn: 'root'
})
export class OrderDataService extends BaseDataService {

  constructor(private http: HttpClient) { super(); }

  aimsReady: string[] = ['NEW', 'DATAMARS_DONE', 'AIMS_NOTIFY_ERROR'];
  datamarsReady: string[] = ['AIMS_AUTH_OK', 'DATAMARS_OK', 'DATAMARS_ERROR'];
  intactReady: string[] = ['AIMS_NOTIFY_OK', 'INTACT_ERROR'];

  getAll(fromDate: Date, toDate: Date): Observable<Order[]> {
    return this.http.get<Order[]>(`${this.apiUrl}/order/${moment(fromDate).format('yyyy-MM-DD')}/${moment(toDate).format('yyyy-MM-DD')}`);
  }


  getSingle(id: Guid): Observable<Order> {
    return this.http.get<Order>(`${this.apiUrl}/order/${id}`);
  }


  delete(id: Guid): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/order/${id}`);
  }

  save(model: Order): Observable<Order> {
    if (model?.id) {
      return this.http.put<Order>(`${this.apiUrl}/order`, model);
    } else {
      return this.http.post<Order>(`${this.apiUrl}/order`, model);
    }
  }

  lastWoo(): Observable<Date> {
    return this.http.get<Date>(`${this.apiUrl}/order/last-woo-check`);
  }

  importWoo(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/order/import-woo`, null);
  }

  submitAimsRequest(ids: Guid[]): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/aims/order`, ids);
  }

  submitAimsPOD(ids: Guid[]): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/aims/pod`, ids);
  }


  aimsTagsApproved(orderId: Guid): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/aims/tags-approved/${orderId}`);

  }

  getAimsTags(orderId: Guid): Observable<AimsOrderTag[]> {
    return this.http.get<AimsOrderTag[]>(`${this.apiUrl}/aims/tags/${orderId}`);
  }

  ovverrideOrderStatus(orderId: Guid, status: string): Observable<Order> {
    return this.http.put<Order>(`${this.apiUrl}/order/status/${orderId}/${status}`, null);
  }

  getAIMSTagXML(id: Guid) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
      // headers: new HttpHeaders({
      //   'Authorization': this.jwt.tokenGetter(),
      // });
    };

    return this.http.get<HttpResponse<any>>(`${this.apiUrl}/aims/${id}/xml`, httpOptions);

  }
}
