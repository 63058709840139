import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { EventEmitter } from 'events';
import { Guid } from 'guid-typescript';
import { AimsOrderTag } from 'src/app/shared/models/aims-order.model';
import { CustomerLookup } from 'src/app/shared/models/customer.model';
import { DataMarsOrder, Order, OrderLine } from 'src/app/shared/models/order.model';
import { Product } from 'src/app/shared/models/product.model';
import { CustomerDataService } from 'src/app/shared/services/customer.service';
import { DataMarsService } from 'src/app/shared/services/datamars.service';
import { OrderDataService } from 'src/app/shared/services/order.service';
import { ProductDataService } from 'src/app/shared/services/product.service';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  @ViewChild(DxFormComponent, { static: false }) orderForm: DxFormComponent

  allowSave: boolean = false;
  private _model: Order;
  get model(): Order {
    return this._model;
  }
  set model(value: Order) {
    this._model = value;
    this.orderChangeAllowed = this.model == null
      || !this.model.statusText
      || this.model.statusText === 'NEW';

  }

  orderChangeAllowed: boolean = false;


  datamarsOrder: DataMarsOrder;

  products: Array<Product> = [];
  customers: Array<CustomerLookup> = [];
  customersDataSource: DataSource;
  herdNo: string;

  tabs = [{ text: 'Order Lines' }, { text: 'Special Notes' }]
  selectedOrders: Array<Order> = [];
  selectedTab: number = 0;

  aimsTags: AimsOrderTag[] = [];
  orderStatusOptions = ['NEW', 'WIP', 'AIMS_AUTH_OK', 'AIMS_AUTH_ERROR', 'DATAMARS_OK', 'DATAMARS_ERROR', 'DATAMARS_DONE',
    'AIMS_NOTIFY_OK', 'AIMS_NOTIFY_ERROR', 'COMPLETED', 'DELETED']


  moreTagsPopupVisible: boolean = false;
  moreTagsProduct: Product;
  moreTagsList: string;
  tagSummary: string;

  constructor(private orderService: OrderDataService,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerDataService,
    private productService: ProductDataService,
    private datamarsService: DataMarsService) { }

  ngOnInit(): void {
    this.model = new Order();

    this.customerService.getLookups().subscribe(x => {
      this.customers = x;
      this.customersDataSource = new DataSource({
        store: x,
        paginate: true,
        pageSize: 10
      });
    });

    this.productService.getAll()
      .subscribe(x => {
        this.products = x;
        this.loadOrder();
      });


    //need to bind so event get raised in the context of thisc omponent
    this.setProduct = this.setProduct.bind(this);
    this.setQuantity = this.setQuantity.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
  }

  loadOrder(): void {

    this.route.params.subscribe(params => {
      const id = params.id;
      if (id) {
        this.loadData(Guid.parse(id));
      }
    });
  }




  traderSelectionChanged(e) {
    this.validateScreen();
  }

  getProductDisplayExpr(item: Product) {
    if (!item) {
      return "";
    }

    return `${item.code} -  ${item.name}`;
  }

  validateScreen() {
    let valid = this.orderForm.instance.validate().isValid;
    this.allowSave = valid; // && this.orderChangeAllowed;
  }
  onFieldDataChanged(e) {
    //console.log(e);

    if (e.dataField === "traderId") {
      const customer = this.customers.find(c => c.id == e.value);
      if (customer) {
        this.herdNo = customer?.herdNo
      }
    }

    this.validateScreen();
  }

  loadData(id: Guid) {

    this.tagSummary = '';

    this.orderService.getSingle(id)
      .subscribe(x => {
        this.model = x;
        this.selectedOrders = [x];
        this.herdNo = this.model.herdNo;
        this.datamarsService.getOrder(x.id)
          .subscribe(dm => {
            this.datamarsOrder = dm;
          })
      });
    this.orderService.getAimsTags(id)
      .subscribe(x => {
        this.aimsTags = x;
        if (x && x.length > 0) {
          var sorted = x
            .map(a => ({ fullTag: a.tagNum, lastFour: a.tagNum.substring(a.tagNum.length - 4) }))
            .sort((a, b) => a.lastFour > b.lastFour ? 1 : -1);
          this.tagSummary = `Tags: ${sorted[0].fullTag} - ${sorted[sorted.length - 1].fullTag}`;
        }
      });
  }
  setProduct(newData: OrderLine, value: Guid, currentRowData: any) {
    newData.productId = value.toString();
    const product = this.products.find(x => x.id === value.toString());
    newData.name = product.name;
    newData.qty = (currentRowData.qty) ? currentRowData.qty : 1;
    newData.herdNo = (currentRowData.herdNo) ? currentRowData.herdNo : this.herdNo;
    newData.tag = null;
    newData.isTagRequired = product.requireTag;
    // newData.TotalPrice = currentRowData.Price * value;
  }

  setQuantity(newData: OrderLine, value: number, currentRowData: any) {
    // const product = this.products.find(x => x.id === currentRowData.productId);
    console.log('qty changed');
    newData.qty = value;
  }

  orderLinesChanged() {
    console.log('orderLinesChanged');
    this.validateScreen();
  }

  saveOrder() {
    this.orderService.save(this.model)
      .subscribe(x => {
        if (x) {
          this.loadData(x.id);
          notify('Order saved', 'success');
          //this.router.navigate(['/data/orders']);
        }
      });
  }

  validateTagValue(e): boolean {
    return !e.data.isTagRequired || e.data.tag;
  }

  onEditorPreparing(e) {
    // if (e.parentType === "dataRow" && e.dataField === "productId") {
    //   e.editorOptions.itemTemplate = "productSelectTemplate";
    // }

    if (e.parentType === "dataRow" && e.dataField === "tag") {
      // const productId = e.row.data.productId;
      // const product = this.products.find(p => p.id === productId);

      e.editorOptions.disabled = e.row.data?.isTagRequired === false;
    }
  }

  apiResponse(success: boolean) {

    if (success) {
      notify('Request successful', 'success');
    } else {
      notify('Request failed', 'error');
    }
    this.loadData(this.model?.id);
  }

  checkDatamarsCallback(success: boolean) {
    //debugger;
  }

  allowRowEdit(e: any): boolean {
    const orderLine: OrderLine = e.row.data;
    return !orderLine.deptProcessed;
  }

  overrideStatus(status: string) {
    this.orderService.ovverrideOrderStatus(this.model.id, status)
      .subscribe(x => {
        this.loadData(this.model?.id);

      });

  }

  moreTags(e: OrderLine) {
    this.moreTagsProduct = null;
    this.moreTagsPopupVisible = false;
    this.moreTagsList = null;
    const productId = e.productId;
    const product = this.products.find(p => p.id === productId);
    if (product) {
      this.moreTagsProduct = product;
      this.moreTagsPopupVisible = true;
    } else {

    }
  }

  submitMoreTags() {
    const tags = this.moreTagsList
      .replace(/\n/g, ',')
      .replace(/\s/g, '')
      .split(',')
    if (tags.length > 0) {
      tags.forEach(tag => {
        let line = new OrderLine();
        line.id = Guid.create().toString();
        line.productId = this.moreTagsProduct.id;
        line.qty = 1;
        line.tag = tag;
        line.name = this.moreTagsProduct.name;
        line.isTagRequired = this.moreTagsProduct.requireTag;
        this.model.lines.push(line);
        this.validateScreen();
      })
    }

    this.moreTagsPopupVisible = false;
  }
  downloadXML() {
    this.orderService.getAIMSTagXML(this.model.id)
      .subscribe(response => {
        console.log(response);

        let fileName = 'file';
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        const fileContent = response.body;

        FileSaver.saveAs(fileContent, fileName);
      });
  }

  onToolbarPreparing(e: any) {
    var toolbarItems = [{ location: 'before', template: 'downloadXML' }];
    e.toolbarOptions.items = toolbarItems.concat(e.toolbarOptions.items);

  }
}


