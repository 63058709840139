import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { Guid } from 'guid-typescript';
import { Order } from '../../models/order.model';
import { IntactDataService } from '../../services/intact.service';

@Component({
  selector: 'app-send-intact-button',
  templateUrl: './send-intact-button.component.html',
  styleUrls: ['./send-intact-button.component.scss']
})
export class SendIntactButtonComponent implements OnInit {

  allowedStatuses: string[] = ['AIMS_NOTIFY_OK'];
  allowIntactSubmit: boolean;
  caption: string = 'Send to Intact';

  private _selectedOrders: Array<Order> = [];
  @Input('selectedOrders')
  get selectedOrders(): Array<Order> { return this._selectedOrders; }
  set selectedOrders(orders: Array<Order>) { this._selectedOrders = orders; this.selectedOrdersChanged(); }


  @Output() intactSubmitted = new EventEmitter<boolean>();

  constructor(private intactService: IntactDataService) { }

  ngOnInit(): void {
  }


  selectedOrdersChanged() {

    this.allowIntactSubmit = this.selectedOrders && this.selectedOrders.length > 0
      && this.selectedOrders.filter(o => this.allowedStatuses.indexOf(o.statusText) > -1).length
      === this._selectedOrders.length;

  }


  executeIntactRequest() {

    let ids: Array<Guid>;
    let task;


    ids = this.selectedOrders
      .filter(o => this.allowedStatuses.indexOf(o.statusText) > -1)
      .map(o => o.id);
    task = this.intactService.submit(ids);

    task.subscribe(x => {
      if (this.caption === 'CHECK') {
        notify(`Intact Order status: ${x}`, 'info');
      } else {
        if (!x) notify('Task finished with errors', 'error');
      }
      this.intactSubmitted.emit(true);
    },
      e => {
        notify('Task finished with errors', 'error');
        this.intactSubmitted.emit(false);
      });
  }


}
