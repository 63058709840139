export class Customer {
    id: string;
    code: string;
    name: string;
    herdNo: string;

    webAddress: string;
    email: string;
    phoneNo: string;

    address1: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    country: string;

    accountNo: string;
    refId: string;

    active: boolean;
}

export class CustomerLookup {
    id: string;
    code: string;
    name: string;
    herdNo: string;
}