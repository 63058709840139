import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { DxDataGridModule, DxFormModule } from 'devextreme-angular';
import { SharedModule } from './shared/shared.module';
import { DataComponent } from './pages/data/data.component';
import { CustomerComponent } from './pages/data/customers/customer/customer.component';
import { CustomersComponent } from './pages/data/customers/customers.component';
import { ErrorComponent } from './pages/aims/errors/error/error.component';
import { ErrorsComponent } from './pages/aims/errors/errors.component';
import { ProductComponent } from './pages/data/products/product/product.component';
import { ProductsComponent } from './pages/data/products/products.component';
import { OrdersComponent } from './pages/data/orders/orders.component';
import { OrderComponent } from './pages/data/orders/order/order.component';
import { SpeciesComponent } from './pages/data/species-list/species/species.component';
import { SpeciesListComponent } from './pages/data/species-list/species-list.component';
import { MsalGuard } from '@azure/msal-angular';
import { DatamarsComponent } from './pages/datamars/datamars.component';
import { LookupComponent } from './pages/datamars/lookup/lookup.component';
import { DatamarsProductsComponent } from './pages/datamars/datamars-products/datamars-products.component';
import { DatamarsComponentSetsComponent } from './pages/datamars/datamars-component-sets/datamars-component-sets.component';

const routes: Routes = [
  {
    path: 'tasks',
    component: TasksComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'aims', component: DataComponent, canActivate: [MsalGuard],
    children: [
      { path: 'error', component: ErrorComponent },
      { path: 'error/:id', component: ErrorComponent },
      { path: 'errors', component: ErrorsComponent }
    ]
  },
  {
    path: 'data', component: DataComponent, canActivate: [MsalGuard],
    children: [
      { path: 'customer', component: CustomerComponent },
      { path: 'customer/:id', component: CustomerComponent },
      { path: 'customers', component: CustomersComponent },
      { path: 'product', component: ProductComponent },
      { path: 'product/:id', component: ProductComponent },
      { path: 'products', component: ProductsComponent },
      { path: 'species', component: SpeciesComponent },
      { path: 'species/:id', component: SpeciesComponent },
      { path: 'species-list', component: SpeciesListComponent },
      { path: 'order', component: OrderComponent },
      { path: 'order/:id', component: OrderComponent },
      { path: 'orders', component: OrdersComponent }
    ]
  },
  {
    path: 'datamars', component: DatamarsComponent, canActivate: [MsalGuard],
    children: [
      { path: 'lookup', component: LookupComponent },
      { path: 'products', component: DatamarsProductsComponent },
      { path: 'component-sets', component: DatamarsComponentSetsComponent },

    ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), DxDataGridModule, DxFormModule, SharedModule],
  providers: [],
  exports: [RouterModule],
  declarations: [HomeComponent, ProfileComponent, TasksComponent]
})
export class AppRoutingModule { }
