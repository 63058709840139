<div class="content-block">
  <div class="row">
    <div class="col-4">
      <h2>
        Order&nbsp;<strong>{{ model?.orderNo }}</strong>
      </h2>
    </div>
    <div class="col-8 text-right">
      <h3>{{ model.statusText }}</h3>
    </div>
  </div>
</div>

<div class="content-block dx-card responsive-paddings">
  <div class="row">
    <div class="col-12 col-md-4 text-center">
      <app-send-aims-button [(selectedOrders)]="selectedOrders" (aimsSubmitted)="apiResponse($event)">
      </app-send-aims-button>
    </div>
    <div class="col-12 col-md-4 text-center">
      <app-send-datamars-button [(selectedOrders)]="selectedOrders" (datamarsSubmitted)="apiResponse($event)">
      </app-send-datamars-button>
    </div>
    <div class="col-12 col-md-2">
      <dx-button type="warning" text="cancel" class="btn w-100" routerLink="/data/orders">
      </dx-button>
    </div>
    <div class="col-12 col-md-2">
      <dx-button type="default" text="save" class="btn w-100" (onClick)="saveOrder()">
      </dx-button>
    </div>
  </div>
  <div class="row mt-2 mb-2">
    <div class="col-8 col-md-4">
      <dx-select-box [items]="orderStatusOptions" placeholder="Override status" [showClearButton]="true"
        #overrideOrderStatus>
      </dx-select-box>
    </div>
    <div class="col-4 col-md-4">
      <dx-button text="Set Status" class="btn btn-danger w-100" type="danger" [disabled]="!overrideOrderStatus?.value"
        (click)="overrideStatus(overrideOrderStatus.value)"></dx-button>
    </div>
  </div>
  <dx-form [(formData)]="model" labelLocation="top" optionalMark="opt" [showOptionalMark]="false"
    (onFieldDataChanged)="onFieldDataChanged($event)">

    <dxi-item itemType="group" [colCount]="6">
      <dxi-item dataField="eCommerceRefId" [editorOptions]="{ disabled: true} ">
        <dxo-label text="WOO No."></dxo-label>
      </dxi-item>
      <dxi-item dataField="traderId" editorType="dxSelectBox" [editorOptions]="{
          dataSource: customersDataSource,
          displayExpr: 'name',
          valueExpr: 'id',
          searchEnabled: true,
          searchMode: 'contains',
          searchExpr: ['name', 'herdNo']
        }" [colSpan]="2">
        <dxo-label text="Customer"></dxo-label>
        <dxi-validation-rule type="required" message="Customer is required">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item>
        <dxo-label text="Herd No"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [disabled]="true" [value]="herdNo"> </dx-text-box>
        </div>
      </dxi-item>

      <dxi-item dataField="deliveryTraceId" [editorOptions]="{ readOnly: false }">
        <dxo-label text="Tracking Info"></dxo-label>
      </dxi-item>
      <dxi-item dataField="shippingCost" [editorOptions]="{ disabled: true }">
        <dxo-label text="Shipping Costs"></dxo-label>
      </dxi-item>
    </dxi-item>

    <dxi-item>
      <div *dxTemplate>
        <dx-tabs [(selectedIndex)]="selectedTab">
          <dxi-item>
            <span>Order Lines</span>
          </dxi-item>
          <dxi-item>
            <span>AIMS</span>
          </dxi-item>
          <dxi-item>
            <span>Other details</span>
          </dxi-item>
        </dx-tabs>
      </div>
    </dxi-item>
    <dxi-item *ngIf="selectedTab === 0">
      <dx-data-grid id="linesGrid" [remoteOperations]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [showBorders]="false" keyExpr="id" [dataSource]="model?.lines"
        (onEditorPreparing)="onEditorPreparing($event)" (onRowUpdated)="orderLinesChanged()"
        (onRowRemoved)="orderLinesChanged()" (onSaved)="orderLinesChanged()">
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-editing mode="row" [allowUpdating]="orderChangeAllowed" [allowDeleting]="orderChangeAllowed"
          [allowAdding]="orderChangeAllowed">
        </dxo-editing>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>

        <dxi-column dataField="productId" caption="Product" width="150" [setCellValue]="setProduct">
          <dxo-lookup [dataSource]="products" displayExpr="code" valueExpr="id" minSearchLength="3" width="350"
            searchEnabled="true" searchMode="contains" placeholder="Select product">
          </dxo-lookup>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="name" caption="Details" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="tag" visible="false" width="140">
          <dxi-validation-rule type="custom" message="Invalid Tag" [validationCallback]="validateTagValue">
          </dxi-validation-rule>
        </dxi-column>

        <dxi-column [width]="50" [(visible)]="orderChangeAllowed" cellTemplate="moreTagsTemplate">
        </dxi-column>
        <div *dxTemplate="let d of 'moreTagsTemplate'">
          <dx-button icon="fad fa-tags" hint="Multiple Tags" [visible]="d.data.isTagRequired" (click)="moreTags(d.data)"
            type="info">
          </dx-button>
        </div>

        <dxi-column dataField="qty" width="50" [setCellValue]="setQuantity" editorOptions="{dataType: 'number'}">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <div *dxTemplate="let item of 'productSelectTemplate'">
          <div>{{ item.code }} - {{ item.name }}</div>
        </div>
        <dxi-column cellTemplate="cellAMS" caption="AMS" [width]="45"></dxi-column>
        <div *dxTemplate="let d of 'cellAMS'">
          <dx-check-box [value]="d.data.deptProcessed" [readOnly]="true" [hint]="d.data.deptOrderNum"></dx-check-box>
        </div>

        <dxi-column type="buttons" [width]="110" [(visible)]="orderChangeAllowed">
          <dxi-button name="edit"></dxi-button>
          <dxi-button name="delete"></dxi-button>
        </dxi-column>
      </dx-data-grid>
    </dxi-item>
    <dxi-item *ngIf="selectedTab === 1">
      <div *dxTemplate class="text-smaller">
        <div class="text-bigger text-center">{{tagSummary}}</div>
        <dx-data-grid id="aimsLinesGrid" [remoteOperations]="false" [allowColumnReordering]="false"
          [rowAlternationEnabled]="true" [showBorders]="false" [dataSource]="aimsTags"
          (onToolbarPreparing)="onToolbarPreparing($event)">
          <dxo-paging [pageSize]="10"></dxo-paging>
          <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false">
          </dxo-editing>
          <dxo-export [enabled]="true" fileName="xtrace-order-aims-tags" [allowExportSelectedData]="true">
          </dxo-export>
          <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
          <dxo-grouping [contextMenuEnabled]="false"> </dxo-grouping>
          <dxi-column dataField="tagNum"></dxi-column>
          <dxi-column dataField="eidTag"></dxi-column>
          <dxi-column dataField="created" caption="Processed On" dataType="date">
            <dxo-format type="shortDateShortTime"> </dxo-format>
          </dxi-column>
          <dxi-column dataField="createdBy" caption="Processed By"></dxi-column>
          <dxi-column dataField="productText" [groupIndex]="0" groupCellTemplate="productTextTemplate">
            <div *dxTemplate="let g of 'productTextTemplate'">
              <div>{{ g.data.key }}</div>
              <div>Total: {{ g.summaryItems[0].value }}</div>
              <div class="small-text">{{ g.groupContinuedMessage }}</div>
              <div class="small-text">{{ g.groupContinuesMessage }}</div>
            </div>
          </dxi-column>
          <div *dxTemplate="let data of 'downloadXML'">
            <div>

              <dx-button stylingMode='text' type='normal' class="toolbar-button" (click)="downloadXML()"
                [hint]="'Refresh data'">

                <span class="fal fa-file-code"></span>
                <span class="ml-2"> AIMS XML</span>

              </dx-button>
            </div>
          </div>
          <dxo-group-panel [visible]="false"></dxo-group-panel>
          <dxo-summary>
            <dxi-group-item column="tagNum" summaryType="count">
            </dxi-group-item>
          </dxo-summary>
        </dx-data-grid>
      </div>
    </dxi-item>
    <dxi-item *ngIf="selectedTab === 2">
      <div *dxTemplate class="text-smaller">
        <div class="row">
          <div class="col-12 border-bottom mb-2">
            <div class="small-text">Aims Id: {{ model?.aimsRequestId }}</div>
            <div class="small-text">
              DataMars Order: {{ datamarsOrder?.number }}, Status
              {{ datamarsOrder?.statusText }}
            </div>
          </div>
          <div class="col-12">
            <dx-text-area [height]="350" [readOnly]="true" [(value)]="model.log">
            </dx-text-area>
          </div>
        </div>
      </div>
    </dxi-item>
  </dx-form>
</div>

<dx-popup [width]="600" [height]="450" [showTitle]="true" title="Copy Tags" [dragEnabled]="false"
  [closeOnOutsideClick]="false" [(visible)]="moreTagsPopupVisible" #moreTagsPopup>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-12">
        Create multiple tag entries for:
        <strong>{{ moreTagsProduct?.code }} - {{ moreTagsProduct?.name }}</strong>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <dx-text-area [height]="250" [(value)]="moreTagsList"> </dx-text-area>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-6">
        <dx-button text="Cancel" type="info" class="btn w-100" (click)="moreTagsPopup.visible = false">
        </dx-button>
      </div>
      <div class="col-6">
        <dx-button text="Create" type="default" class="btn w-100" (click)="submitMoreTags()"></dx-button>
      </div>
    </div>
  </div>
</dx-popup>