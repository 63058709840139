import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import locale_ie from '@angular/common/locales/en-IE';
import { registerLocaleData } from '@angular/common';

import { HttpProgressInterceptor } from './shared/services/http-progress-interceptor';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule } from './shared/components';
import { ScreenService, AppInfoService } from './shared/services';
import { DataComponent } from './pages/data/data.component';
import { CustomerComponent } from './pages/data/customers/customer/customer.component';
import { CustomersComponent } from './pages/data/customers/customers.component';

import { AppRoutingModule } from './app-routing.module';
import { DataService } from './shared/services/data.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel, Configuration } from "@azure/msal-browser";
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';

import {
  DxButtonModule, DxDataGridModule, DxFormModule, DxSelectBoxModule,
  DxTextBoxModule, DxDateBoxModule, DxTabsModule, DxTextAreaModule, DxValidatorModule,
  DxPopupModule, DxCheckBoxModule
} from 'devextreme-angular';
import { AimsComponent } from './pages/aims/aims.component';
import { ErrorsComponent } from './pages/aims/errors/errors.component';
import { ErrorComponent } from './pages/aims/errors/error/error.component';
import { ProductsComponent } from './pages/data/products/products.component';
import { ProductComponent } from './pages/data/products/product/product.component';
import { OrdersComponent } from './pages/data/orders/orders.component';
import { OrderComponent } from './pages/data/orders/order/order.component'
import { SpeciesListComponent } from './pages/data/species-list/species-list.component';
import { SpeciesComponent } from './pages/data/species-list/species/species.component';
import { SharedModule } from './shared/shared.module';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { OrderTagPipe } from './shared/pipes/order-tag-details.pipe';
import { DatamarsComponent } from './pages/datamars/datamars.component';
import { LookupComponent } from './pages/datamars/lookup/lookup.component';
import { DatamarsProductsComponent } from './pages/datamars/datamars-products/datamars-products.component';
import { DatamarsComponentSetsComponent } from './pages/datamars/datamars-component-sets/datamars-component-sets.component';


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(environment.msalConfig);
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  api: {
    endpoint: environment.apiUrl,
    scopes: [environment.msalConfig.auth.scope],
  },
}

/**
 * MSAL Angular will automatically retrieve tokens for resources 
 * added to protectedResourceMap. For more info, visit: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.api.endpoint, protectedResources.api.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export const loginRequest = {
  scopes: []
};

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

registerLocaleData(locale_ie, 'en-IE');

@NgModule({
  declarations: [
    AppComponent,
    CustomerComponent, CustomersComponent, DataComponent, AimsComponent, ErrorsComponent, ErrorComponent,
    ProductsComponent, ProductComponent, OrdersComponent, OrderComponent, SpeciesListComponent, SpeciesComponent,
    OrderTagPipe,
    DatamarsComponent,
    LookupComponent,
    DatamarsProductsComponent,
    DatamarsComponentSetsComponent
  ],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    HttpClientModule,
    AppRoutingModule,
    DxButtonModule,
    DxDataGridModule,
    DxFormModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxTabsModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxPopupModule,
    DxCheckBoxModule,
    SharedModule,
    MsalModule
  ],
  providers: [AppInfoService, DataService, ScreenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,

    { provide: LOCALE_ID, useValue: 'en-IE' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerService, multi: true },
    OrderTagPipe

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
