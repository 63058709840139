<h2 class="content-block">Products</h2>
<div class="content-block">
    <div class="dx-card responsive-paddings">
        <div class="row">
            <div class="col-12 text-center">
                <dx-data-grid id="gridCustomers" keyExpr="id" (onRowRemoving)="onRowRemoving($event)"
                    (onInitNewRow)="onInitNewRow($event)" [dataSource]="records" [allowColumnResizing]="true">
                    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="productFileListing">
                    </dxo-state-storing>
                    <dxo-editing [allowUpdating]="false" [allowAdding]="true" [allowDeleting]="true" mode="form">
                    </dxo-editing>
                    <dxo-export [enabled]="true" fileName="agri-products" [allowExportSelectedData]="true"></dxo-export>
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                    <dxi-column caption="Edit" cellTemplate="editTemplate" [allowEditing]="false" width="100">
                    </dxi-column>
                    <dxi-column dataField="code" [allowEditing]="false" width="100"> </dxi-column>
                    <dxi-column dataField="name" caption="Description"></dxi-column>
                    <dxi-column dataField="eCommerceRef" caption="Woo" width="80"></dxi-column>
                    <dxi-column dataField="dataMarsRef" caption="DataMars" width="120"></dxi-column>
                    <dxi-column dataField="tagTypeId" caption="AMS Tag Id" width="60"></dxi-column>
                    <dxi-column dataField="isSet" caption="Set" width="60"></dxi-column>
                    <dxi-column dataField="requireTag" caption="Tag" width="60"></dxi-column>
                    <dxi-column dataField="active" width="80"></dxi-column>
                    <div *dxTemplate="let d of 'editTemplate'">
                        <div class="text-left">
                            <a [routerLink]="['/data/product', d.data.id]"><i class="fa-light fa-pen-to-square"></i></a>
                        </div>

                    </div>
                </dx-data-grid>
            </div>
        </div>

    </div>
</div>