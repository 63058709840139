import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { AIMsError } from '../models/aims-error.model';
import { BaseDataService } from './base-data.service';

@Injectable({
  providedIn: 'root'
})
export class AimsErrorService extends BaseDataService{


 constructor(private http: HttpClient) { super(); }

  // customer
  getAll(): Observable<AIMsError[]> {
      return this.http.get<AIMsError[]>((`${this.apiUrl}/aimserror`));
  }
  delete(id: Guid): Observable<any> {
      return this.http.delete((`${this.apiUrl}/aimserror/${id}`));
  }

  getSingle(id: Guid): Observable<AIMsError> {
      return this.http.get<AIMsError>(`${this.apiUrl}/aimserror/${id}`);
  }

  save(model: AIMsError): Observable<AIMsError> {
      if (!model.id) {
          return this.http.post<AIMsError>(`${this.apiUrl}/aimserror`, model);
      } else {
          return this.http.put<AIMsError>(`${this.apiUrl}/aimserror`, model);
      }
  }

}
