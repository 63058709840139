import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Species } from 'src/app/shared/models/species.model';
import { SpeciesService } from 'src/app/shared/services/species.service';

@Component({
  selector: 'app-species-list',
  templateUrl: './species-list.component.html',
  styleUrls: ['./species-list.component.scss']
})
export class SpeciesListComponent implements OnInit {

  species: Array<Species> = [];
  constructor(private speciesService: SpeciesService,
    private router: Router) { }

  ngOnInit(): void {
    this.loadSpecies();
  }


  loadSpecies() {
    this.speciesService.getAll()
      .subscribe(x => {
        this.species = x;
      });
  }

  onInitNewRow(e) {
    this.router.navigate(['/data/species']);
  }

  onRowUpdating(e: any) {
    e.cancel = this.SaveSpecies(e, false);
  }

  onRowRemoving(e: any) {
    e.data.active = false;
    const result = this.speciesService.delete(e.data.id).toPromise();
    e.cancel = new Promise((resolve, reject) => {
      result.then((x) => {
        resolve(x);
      })
        .catch(() => reject());
    });
  }

  private SaveSpecies(e: any, insert: boolean): Promise<any> {
    const result = this.speciesService.save(e.data).toPromise();
    return new Promise((resolve, reject) => {
      result.then((x) => {

        resolve(x);
      }).catch(() => {
        reject();
      });
    });
  }

}
