import { Guid } from 'guid-typescript';

export class Product {
    id: string;
    code: string;
    shortName: string;
    name: string;
    eCommerceRef: string;
    dataMarsRef: string;
    active: boolean = true;
    productType: string;
    price: number;
    
    tagTypeId: number;
    speciesId: string;
    requireTag: boolean;
    isSet: boolean;
}
