<h2 class="content-block">Species&nbsp;<strong>{{species?.code}}</strong></h2>

<div class="content-block dx-card responsive-paddings">
    <div class="row">
        <div class="col-12 offset-lg-8 col-lg-2">
            <dx-button type="warning" text="cancel" class="btn w-100" routerLink="/data/species-list"> </dx-button>
        </div>
        <div class="col-12 col-lg-2">
            <dx-button type="default" text="save" class="btn w-100" [disabled]="!formValid"
                (onClick)="saveSpecies(species)"> </dx-button>
        </div>
    </div>
    <dx-form [(formData)]="species" labelLocation="top" optionalMark="opt" [showOptionalMark]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)">
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item dataField="code">
                <dxi-validation-rule type="required" message="Code is required">
                </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="active" editorType="dxCheckBox" [editorOptions]="{ width: '100%'}"></dxi-item>
        </dxi-item>
        <dxi-item dataField="description">
            <dxi-validation-rule type="required" message="Description required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item colCount=2 itemType="group">
            <dxi-item dataField="deptSpeciesId" editorType="dxNumberBox">
                <dxi-validation-rule type="required" message="Tag Type required">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="dataMarsSpeciesCode">
                <dxo-label text="Datamars Species Code (CATTLE, SHEEP, GOAT)"></dxo-label>
                <dxi-validation-rule type="required" message="Datamars species code required">
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
    </dx-form>

</div>