import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { DataMarsProduct } from '../models/datamars-product.model';
import { DataMarsOrder } from '../models/order.model';
import { BaseDataService } from './base-data.service';

@Injectable({
  providedIn: 'root'
})
export class DataMarsService  extends BaseDataService{

  constructor(private http: HttpClient) { super(); }

  
  pingDataMars(): Observable<string> {
    return this.http.get(`${this.apiUrl}/datamars/me`, {responseType: 'text'});
  }

  getProducts(): Observable<DataMarsProduct[]> {
    return this.http.get<DataMarsProduct[]>(`${this.apiUrl}/datamars/products`);
  }

  getComponentSets(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/datamars/component-sets`);

  }

  submitDataMarsRequest(ids: Array<Guid>) : Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/datamars/submit`, ids);
  }

  checkArticleNo(articleNo: string) : Observable<Guid> {
    return this.http.get<Guid>(`${this.apiUrl}/datamars/check-article/${articleNo}`);
  }

  getOrder(id: Guid): Observable<DataMarsOrder> {
    return this.http.get<DataMarsOrder>(`${this.apiUrl}/datamars/${id}`);
  }

  checkOrderStatus(ids: Guid[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/datamars/check`, ids, {responseType: 'text'});
  }
}
