import { NgModule } from "@angular/core";
import { GravatarDirective } from './services/gravatar.directive';
import { SendAimsButtonComponent } from './components/send-aims-button/send-aims-button.component';

import { DxButtonModule } from 'devextreme-angular';
import { SendDatamarsButtonComponent } from './components/send-datamars-button/send-datamars-button.component';
import { SendIntactButtonComponent } from './components/send-intact-button/send-intact-button.component';

@NgModule({
    imports: [DxButtonModule],
    declarations: [GravatarDirective, SendAimsButtonComponent, SendDatamarsButtonComponent, SendIntactButtonComponent],
    exports: [GravatarDirective, SendAimsButtonComponent, SendDatamarsButtonComponent, SendIntactButtonComponent]
})
export class SharedModule { }