import { AfterContentChecked, AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/shared/models/product.model';
import { Species } from 'src/app/shared/models/species.model';
import { DataMarsService } from 'src/app/shared/services/datamars.service';
import { ProductDataService } from 'src/app/shared/services/product.service';
import { SpeciesService } from 'src/app/shared/services/species.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {

  record = new Product();
  formValid: boolean;

  species: Array<Species> = [];

  private sub: Subscription;
  private dataSub: Subscription;

  datamarsErrorMessage: string;
  datamarsSuccessMessage: string;

  datamarsButtonOptions=  {
    text: '',
    icon: 'fad fa-check-double',
    onClick: () => { this.checkArticleNo(); }
  }

  @ViewChild('productForm') productForm;

  constructor(private dataService: ProductDataService,
    private route: ActivatedRoute,
    private router: Router,
    private speciesService: SpeciesService,
    private datamarsService: DataMarsService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      const id = params.id;
      if (id) {
        this.loadData(Guid.parse(id));
      }
    });

    this.speciesService.getAll().subscribe(x => {
      this.species = x;
    });
  }

  onFieldDataChanged(e: any) {
    this.formValid = this.productForm.instance.validate()?.isValid ?? false;
    //e.component.validate()?.isValid ?? false;
  }


  loadData(id: Guid) {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
    this.dataSub = this.dataService.getSingle(id)
      .subscribe(x => {
        this.record = x;
        this.checkArticleNo();
      });
  }

  saveRecord(e: Product) {
    this.dataService.save(e).subscribe(x => {
      if (x) {
        this.router.navigate(['/data/products']);
      }
    });
  }


  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  checkArticleNo() {
    this.datamarsService
    .checkArticleNo(this.record?.dataMarsRef)
    .subscribe(id => {
      this.datamarsSuccessMessage = `Datamars: ${this.record?.dataMarsRef} matched id ${id}`;
      this.datamarsErrorMessage = null;
    },
     e => {
      this.datamarsSuccessMessage = null;
      this.datamarsErrorMessage = `Datamars: article '${this.record?.dataMarsRef ?? 'empty'}' not found`;
     }
    )
  }
}
