<h2 class="content-block">Customers</h2>
<div class="content-block">
    <div class="dx-card responsive-paddings">
        <div class="row">
            <div class="col-12 text-center">
                <dx-data-grid id="gridCustomers" keyExpr="id" (onRowRemoving)="onRowRemoving($event)"
                    (onInitNewRow)="onInitNewRow($event)" [dataSource]="records" [allowColumnResizing]="true"
                    (onToolbarPreparing)="onToolbarPreparing($event)">
                    <dxo-editing [allowUpdating]="false" [allowAdding]="true" [allowDeleting]="true" mode="form">
                    </dxo-editing>
                    <dxo-export [enabled]="true" fileName="xtrace-departments" [allowExportSelectedData]="true">
                    </dxo-export>
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                    <dxi-column caption="Edit" cellTemplate="editTemplate" [allowEditing]="false" width="100">
                    </dxi-column>
                    <dxi-column dataField="code" [allowEditing]="false" width="100"> </dxi-column>
                    <dxi-column dataField="name"></dxi-column>
                    <dxi-column dataField="accountNo"></dxi-column>
                    <dxi-column dataField="telephoneNumber"></dxi-column>
                    <div *dxTemplate="let d of 'codeTemplate'">
                        <a [routerLink]="['/data/customer', d.data.id]">{{d.data.code }}</a>
                    </div>
                    <div *dxTemplate="let d of 'editTemplate'">
                        <div class="text-left">
                            <a [routerLink]="['/data/customer', d.data.id]"><i
                                    class="fa-light fa-pen-to-square"></i></a>
                        </div>

                    </div>

                </dx-data-grid>
            </div>
        </div>

    </div>
</div>