<h2 class="content-block">Customer&nbsp;<strong>{{record?.code}}</strong></h2>


<div class="content-block dx-card responsive-paddings">
    <div class="row">
        <div class="col-12 offset-lg-8 col-lg-2">
            <dx-button type="warning" text="cancel" class="btn w-100" routerLink="/data/customers"> </dx-button>
        </div>
        <div class="col-12 col-lg-2">
            <dx-button type="default" text="save" class="btn w-100" [disabled]="!formValid"
                (onClick)="saveRecord(record)"> </dx-button>
        </div>
    </div>
    <dx-form [(formData)]="record" labelLocation="top" optionalMark="opt" [showOptionalMark]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)">

        <dxi-item itemType="group" [colCount]="3">
            <dxi-item dataField="code">
                <dxi-validation-rule type="required" message="Code is required">
                </dxi-validation-rule>

            </dxi-item>
            <dxi-item dataField="herdNo">
                <dxi-validation-rule type="required" message="Herd No is required">
                </dxi-validation-rule>

            </dxi-item>
            <dxi-item dataField="active" editorType="dxCheckBox">
            </dxi-item>
            <dxi-item dataField="accountNo">
                <dxi-validation-rule type="required" message="Account No is required">
                </dxi-validation-rule>

            </dxi-item>
            <dxi-item dataField="refId">
                <dxi-validation-rule type="required" message="eCommerce Ref No is required">
                </dxi-validation-rule>

            </dxi-item>
        </dxi-item>
        <dxi-item dataField="name">
            <dxi-validation-rule type="required" message="Name is required">
            </dxi-validation-rule>

        </dxi-item>
        <dxi-item itemType="group" [colCount]="3">
            <dxi-item dataField="webAddress"></dxi-item>
            <dxi-item dataField="email"></dxi-item>
            <dxi-item dataField="phoneNo"></dxi-item>

        </dxi-item>
        <dxi-item itemType="group" [colCount]="3">
            <dxi-item dataField="address1"></dxi-item>
            <dxi-item dataField="address2"></dxi-item>
            <dxi-item dataField="address3"></dxi-item>
            <dxi-item dataField="city"></dxi-item>
            <dxi-item dataField="state"></dxi-item>
            <dxi-item dataField="country"></dxi-item>


        </dxi-item>
    </dx-form>
</div>