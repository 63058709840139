import { Component, OnInit } from '@angular/core';
import { DataMarsProduct } from 'src/app/shared/models/datamars-product.model';
import { DataMarsService } from 'src/app/shared/services/datamars.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-datamars-products',
  templateUrl: './datamars-products.component.html',
  styleUrls: ['./datamars-products.component.scss']
})
export class DatamarsProductsComponent implements OnInit {

  records: Array<DataMarsProduct> = [];
  constructor(private datamarsService: DataMarsService) { }

  ngOnInit(): void {
    this.datamarsService.getProducts()
      .subscribe(x => {
        this.records = x;
      });

    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard(e) {
    const id = e.row.data.id;
    navigator.clipboard.writeText(id)
      .then(() => {
        notify(`${id} copied to clipboard`);
      })
      .catch(e => console.error(e))


  }

}
