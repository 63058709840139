<h2 class="content-block">Profile</h2>

<div class="content-block dx-card responsive-paddings">
  <div class="form-avatar">
    <img gravatarImage [gravatarEmail]="account?.name" />
  </div>
</div>

<div class="content-block dx-card responsive-paddings">
  <dx-form id="form" [formData]="account" labelLocation="top" [colCountByScreen]="colCountByScreen">
    <dxi-item dataField="name" [editorOptions]="{disabled: true}"></dxi-item>
    <dxi-item dataField="email" [editorOptions]="{disabled: true}"></dxi-item>
    <dxi-item dataField="tennantId" [editorOptions]="{disabled: true}">
      <dxo-label text="Authorized by"></dxo-label>
    </dxi-item>

  </dx-form>
</div>