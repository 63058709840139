<h2 class="content-block">Orders</h2>
<div class="content-block">
    <div class="dx-card responsive-paddings">
        <div class="row">
            <div class="col-md-3 col-12">
                <app-send-aims-button [(selectedOrders)]="selectedOrders" (aimsSubmitted)="aimsResponse($event)">
                </app-send-aims-button>
            </div>
            <div class="col-md-3 col-12">
                <app-send-datamars-button [(selectedOrders)]="selectedOrders"
                    (datamarsSubmitted)="datamarsResponse($event)">
                </app-send-datamars-button>
            </div>
            <div class="col-md-2 col-12">
                <app-send-intact-button [(selectedOrders)]="selectedOrders" (intactSubmitted)="intactResponse($event)">
                </app-send-intact-button>

            </div>
            <div class="col-12 col-md-4">
                <dx-button type="normal" stylingMode="outlined" (click)="executeWooImport()"
                    text="Sync eCommerce Orders" class="btn w-100 text-bigger">
                </dx-button>
                <div class="text-center " *ngIf="lastWooChecked">
                    last checked @ <span [innerHtml]="lastWooChecked | date: 'medium'"></span>
                </div>
            </div>

            <div class="col-12 text-center pt-3">

                <dx-data-grid keyExpr="id" (onRowRemoving)="onRowRemoving($event)" (onInitNewRow)="onInitNewRow($event)"
                    [dataSource]="filteredRecords" [allowColumnResizing]="true"
                    (onToolbarPreparing)="onToolbarPreparing($event)" keyExpr="id" [(selectedRowKeys)]="selectedIds"
                    (onSelectionChanged)="orderSelectionChanged()">
                    <dxo-sorting mode="single">
                        <!-- or "multiple" | "none" -->
                    </dxo-sorting>
                    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="orderListing">
                    </dxo-state-storing>

                    <dxo-editing [allowUpdating]="false" [allowAdding]="true" [allowDeleting]="false" mode="form">
                    </dxo-editing>
                    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                    <dxo-export [enabled]="true" fileName="xtrace-orders" [allowExportSelectedData]="true"></dxo-export>
                    <dxo-paging [pageSize]="10" [(pageIndex)]="pageIndex"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                    <dxo-selection showCheckBoxesMode="always" mode="multiple"></dxo-selection>
                    <dxi-column caption="No" cellTemplate="codeTemplate" width="80" dataType="string"
                        [allowFiltering]="true"></dxi-column>
                    <dxi-column dataField="customerName" caption="Customer" width="200"></dxi-column>
                    <dxi-column dataField="dateCreated" caption="Order Date" dataType="date" sortIndex="0"
                        sortOrder="desc">
                        <dxo-format type="shortDate">
                        </dxo-format>
                    </dxi-column>
                    <dxi-column dataField="eCommerceRefId" caption="Woo"></dxi-column>
                    <dxi-column caption="Tags: Aims/Total" cellTemplate="tagsTemplate" cssClass="text-center"
                        [allowFiltering]="false">
                    </dxi-column>
                    <dxi-column aption="Status" cellTemplate="statusTemplate" cssClass="text-center" [minWidth]="150">
                    </dxi-column>
                    <div *dxTemplate="let d of 'statusTemplate'">
                        <div class="text-bigger">
                            <i class="fad fa-circle" style="margin-right:1px"
                                [ngClass]="orderStatus(d.data, ['AIMS_NOTIFY_ERROR', 'AIMS_AUTH_OK', 'DATAMARS_DONE', 'DATAMARS_ERROR', 'DATAMARS_OK', 'AIMS_NOTIFY_OK', 'COMPLETE', 'INTACT_ERROR'], ['AIMS_AUTH_ERROR'])"></i>
                            <i class="fad fa-circle" style="margin-right:1px"
                                [ngClass]="orderStatus(d.data, ['AIMS_NOTIFY_ERROR', 'DATAMARS_OK', 'AIMS_NOTIFY_OK', 'DATAMARS_DONE', 'COMPLETE', 'INTACT_ERROR'], ['DATAMARS_ERROR'])"></i>
                            <i class="fad fa-circle" style="margin-right:1px"
                                [ngClass]="orderStatus(d.data, ['AIMS_NOTIFY_ERROR', 'AIMS_NOTIFY_OK', 'DATAMARS_DONE', 'COMPLETE', 'INTACT_ERROR'], ['AIMS_NOTIFY_ERROR'])"></i>
                            <i class="fad fa-circle"
                                [ngClass]="orderStatus(d.data, ['AIMS_NOTIFY_OK', 'COMPLETE'], ['AIMS_NOTIFY_ERROR', 'INTACT_ERROR'])"></i>
                        </div>
                        <div class="text-bigger" [innerHTML]="d.data.statusText"></div>
                    </div>
                    <div *dxTemplate="let d of 'codeTemplate'">
                        <span><a [routerLink]="['/data/order', d.data.id]">{{d.data.orderNo }}</a></span>
                    </div>

                    <div *dxTemplate="let data of 'fromDate'">
                        <span>From: </span>
                        <dx-date-box [(value)]="fromDate" type="date" (onValueChanged)="onDateSelectionChanged()"
                            applyValueMode="useButtons" style="display: inline-block;">
                        </dx-date-box>

                    </div>
                    <div *dxTemplate="let data of 'toDate'" class="pl-4">

                        <span>To: </span>
                        <dx-date-box [(value)]="toDate" type="date" (onValueChanged)="onDateSelectionChanged()"
                            applyValueMode="useButtons" style="display: inline-block;">
                        </dx-date-box>

                    </div>
                    <div *dxTemplate="let d of 'tagsTemplate'" [class]="">
                        <span>{{(d | orderTag : 'aims') | async }}</span>
                        /
                        <span>{{(d | orderTag : 'total') | async}}</span>

                    </div>
                    <div *dxTemplate="let data of 'filterButtons'">
                        <span>Filter: </span>
                        <dx-select-box [(value)]="filter" [items]="filterOptions" placeholder="Choose Filter"
                            displayExpr="text" valueExpr="id" [showClearButton]="true"
                            (onValueChanged)="onFilterValueChanged($event)"
                            style="display: inline-block; padding-right: 0px;">
                        </dx-select-box>
                    </div>
                    <!-- <dxi-column type="buttons" width="60" css>
                        <dxi-button name="delete" icon="fa fa-trash" cssClass="text-secondary"></dxi-button>
                      
                    </dxi-column> -->

                </dx-data-grid>
            </div>
        </div>

    </div>
</div>