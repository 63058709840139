import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/shared/models/userInfo.model';
import { AppInfoService } from 'src/app/shared/services';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  templateUrl: 'profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  account: UserModel;

  colCountByScreen: object;

  constructor(private dataService: DataService,
    private authService: AppInfoService) {

  }

  ngOnInit() {
    this.dataService.test().subscribe(x => {
      console.log(x);
    })

    this.account = this.authService.currentUser();
  }

}
