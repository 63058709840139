<h2 class="content-block">AIMS Error&nbsp;<strong>{{record?.code}}</strong></h2>



<div class="content-block dx-card responsive-paddings">
    <div class="row">
        <div class="col-12 offset-lg-8 col-lg-2">
            <dx-button type="warning" text="cancel" class="btn w-100" routerLink="/aims/errors"> </dx-button>
        </div>
        <div class="col-12 col-lg-2">
            <dx-button type="default" text="save" class="btn w-100" [disabled]="!formValid"
                (onClick)="saveRecord(record)"> </dx-button>
        </div>
    </div>
    <dx-form [(formData)]="record" labelLocation="top" optionalMark="opt" [showOptionalMark]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)">

        <dxi-item itemType="group" [colCount]="2">
            <dxi-item dataField="code">
                <dxi-validation-rule type="required" message="Code is required">
                </dxi-validation-rule>

            </dxi-item>
            <dxi-item dataField="active" editorType="dxCheckBox">
            </dxi-item>
        </dxi-item>
        <dxi-item dataField="description">
            <dxi-validation-rule type="required" message="Description is required">
            </dxi-validation-rule>

        </dxi-item>

    </dx-form>
</div>