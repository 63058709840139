import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from 'src/app/shared/models/customer.model';
import { CustomerDataService } from 'src/app/shared/services/customer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  records: Array<Customer> = [];
  constructor(private customerService: CustomerDataService,
    private router: Router) { }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.customerService.getAll()
      .subscribe(x => {
        this.records = x;
      });
  }
  onInitNewRow(e) {
    this.router.navigate(['/data/customer']);
  }

  onRowRemoving(e: any) {
    e.data.active = false;
    const result = this.customerService.delete(e.data.id).toPromise();
    e.cancel = new Promise((resolve, reject) => {
      result.then((x) => {
        resolve(x);
      })
        .catch(() => reject());
    });
  }
  onToolbarPreparing(e) {
    if (environment.hasEpicorAccess) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'far fa-user-cog',
          onClick: this.executeCustomerImport.bind(this)
        }
      });
    }
  }

  executeCustomerImport() {
    this.customerService.syncFromEpicor()
      .subscribe(x => {
        this.loadData();
      });
  }

}
