import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { Guid } from 'guid-typescript';
import { Order } from '../../models/order.model';
import { DataMarsService } from '../../services/datamars.service';
import { OrderDataService } from '../../services/order.service';

@Component({
  selector: 'app-send-datamars-button',
  templateUrl: './send-datamars-button.component.html',
  styleUrls: ['./send-datamars-button.component.scss']
})
export class SendDatamarsButtonComponent implements OnInit {

  allowedStatuses: string[] = ['AIMS_AUTH_OK', 'DATAMARS_OK'];
  
  allowDatamarsSubmit: boolean;
  caption: string = 'Send to DataMars';

  private _selectedOrders: Array<Order> = [];
  @Input('selectedOrders')
  get selectedOrders(): Array<Order> { return this._selectedOrders; }
  set selectedOrders(orders: Array<Order>) { this._selectedOrders = orders; this.selectedOrdersChanged(); }

  @Output() datamarsSubmitted = new EventEmitter<boolean>();

  constructor(private datamarsService: DataMarsService,
              private orderService: OrderDataService) { }

  ngOnInit(): void {
  }

  selectedOrdersChanged() {
   
      this.allowDatamarsSubmit = this.selectedOrders && this.selectedOrders.length > 0
            && this.selectedOrders.filter(o => this.allowedStatuses.indexOf(o.statusText) > -1).length 
              === this._selectedOrders.length;
  

  }

  executeDataMarsRequest() {

    let ids: Array<Guid>;
    let task;


      ids = this.selectedOrders
        .filter(o => this.allowedStatuses.indexOf(o.statusText) > -1)
        .map(o => o.id);
      task = this.datamarsService.submitDataMarsRequest(ids);

    task.subscribe(x => {
      if (this.caption === 'CHECK') {
        notify(`Datamars Order status: ${x}`, 'info');
      } else {
        if (!x) notify('Task finished with errors', 'error');
      }
           this.datamarsSubmitted.emit(true);
    },
      e => {
        notify('Task finished with errors', 'error');
        this.datamarsSubmitted.emit(false);
      });
  }

}
