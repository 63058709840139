export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Profile',
    path: '/profile',
    icon: 'fad fa-head-side-brain'
  },
  {
    text: 'Data Entry',
    icon: 'fad fa-edit',
    items: [
      {
        text: 'Customers',
        path: '/data/customers'
      },
      {
        text: 'Orders',
        path: '/data/orders'
      },
      {
        text: 'Products',
        path: '/data/products'
      },
      {
        text: 'Species',
        path: '/data/species-list'
      }
    ]
  },
  {
    text: 'AMS gov.ie',
    icon: 'fad fa-cow',
    items: [
      {
        text: 'API Errors',
        path: '/aims/errors'
      }
    ]
  },
  {
    text: 'DataMars',
    icon: 'fad fa-tags',
    items: [
      {
        text: 'Component Sets',
        path: '/datamars/component-sets'
      },
      
      {
        text: 'Products',
        path: '/datamars/products'
      },
      {
        text: 'AgriHealth Profile',
        path: '/datamars/lookup'
      },
    ]
  }
];
