import { Component, OnInit } from '@angular/core';
import { DataMarsService } from 'src/app/shared/services/datamars.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookupComponent implements OnInit {

  lastDataMarsChecked: Date;
  datamarsName: string;
  constructor(private datamarsService: DataMarsService) { }

  ngOnInit(): void {
    this.pingDataMars();
  }


  pingDataMars() {
    this.datamarsService.pingDataMars()
      .subscribe(x => {
        this.lastDataMarsChecked = new Date();
        this.datamarsName = x;
      },
        error => {
          console.log(error);
          notify('DataMars API not available', 'error');
        })
  }

}
