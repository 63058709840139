import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { Observable } from "rxjs/internal/Observable";
import { BaseDataService } from "./base-data.service";

@Injectable({
    providedIn: 'root'
})
export class IntactDataService
    extends BaseDataService {

    constructor(private http: HttpClient) { super(); }


    submit(ids: Array<Guid>): Observable<boolean> {
        return this.http.post<boolean>(`${this.apiUrl}/intact/submit`, ids);
    }

}