import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { Customer } from 'src/app/shared/models/customer.model';
import { CustomerDataService } from 'src/app/shared/services/customer.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnDestroy {

  record = new Customer();
  formValid = false;

  private sub: Subscription;
  private dataSub: Subscription;
  constructor(private customerService: CustomerDataService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      const id = params.id;
      if (id) {
        this.loadData(Guid.parse(id));
      }
    });
  }


  onFieldDataChanged(e: any) {
    this.formValid = e.component.validate().isValid;

  }


  loadData(id: Guid) {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
    this.dataSub = this.customerService.getSingle(id)
      .subscribe(x => {
        this.record = x;
      });
  }

  saveRecord(e: Customer) {
    this.customerService.save(e).subscribe(x => {
      if (x) {
        this.router.navigate(['/data/customers']);
      }
    });
  }


  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

}
