import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/shared/models/product.model';
import { ProductDataService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  records: Array<Product> = [];

  constructor(private dataService: ProductDataService,
    private router: Router) { }


  ngOnInit(): void {
    this.dataService.getAll()
      .subscribe(x => {
        this.records = x;
      });
  }


  onInitNewRow(e) {
    this.router.navigate(['/data/product']);
  }

  onRowRemoving(e: any) {
    e.data.active = false;
    const result = this.dataService.delete(e.data.id).toPromise();
    e.cancel = new Promise((resolve, reject) => {
      result.then((x) => {
        resolve(x);
      })
        .catch(() => reject());
    });
  }

}
