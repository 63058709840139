<h2 class="content-block">DATAMARS Profile</h2>
<div class="content-block">
    <div class="dx-card responsive-paddings">

        <div class="row">
            <div class="col-md-8 col-12">
                <h2>{{datamarsName}}</h2>
            </div>
            <div class="col-12 col-md-4">
                <dx-button type="normal" stylingMode="text" (click)="pingDataMars()" text="Check DataMars connection"
                    class="btn w-100">
                </dx-button>
                <div class="text-center small-text " *ngIf="lastDataMarsChecked">
                    connection last checked @ <span [innerHtml]="lastDataMarsChecked | date: 'medium'"></span>
                </div>
            </div>
        </div>

    </div>
</div>