import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { Customer, CustomerLookup } from '../models/customer.model';
import { BaseDataService } from './base-data.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerDataService extends BaseDataService {

  constructor(private http: HttpClient) { super(); }

  // customer
  getAll(): Observable<Customer[]> {
    return this.http.get<Customer[]>((`${this.apiUrl}/customer`));
  }

  getLookups(): Observable<CustomerLookup[]> {
    return this.http.get<CustomerLookup[]>((`${this.apiUrl}/customer`));
  }

  delete(id: Guid): Observable<any> {
    return this.http.delete((`${this.apiUrl}/customer/${id}`));
  }

  getSingle(id: Guid): Observable<Customer> {
    return this.http.get<Customer>(`${this.apiUrl}/customer/${id}`);
  }

  save(model: Customer): Observable<Customer> {
    if (!model.id) {
      return this.http.post<Customer>(`${this.apiUrl}/customer`, model);
    } else {
      return this.http.put<Customer>(`${this.apiUrl}/customer`, model);
    }
  }

  syncFromEpicor(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/customer/epicor-sync`, null);
  }
}