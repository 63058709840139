import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Order } from '../models/order.model';
import { OrderDataService } from '../services/order.service';

@Pipe({ name: 'orderTag' })
export class OrderTagPipe implements PipeTransform {

    constructor(private orderService: OrderDataService) {

    }
    transform(gridData: any, kind: string): Observable<number>{
        const order: Order = gridData.data;
        if (!order || !order.lines) return of(0);

        if (kind === 'total') {
            let qty = 0;
            order.lines
                .filter(ln => ln.deptTagTypeId > 0) //only count AIMS tags
                .forEach(line => {
                    qty += line.qty;
                });
            return of(qty);
        }
        if (kind === 'aims') {
            return this.orderService.aimsTagsApproved(order.id);
        }
        return of(0);
    }
}