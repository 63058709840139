import { Guid } from 'guid-typescript';

export class Order {
    id: Guid;
    orderNo: string;
    dateCreated: Date;
    status: string;
    statusText: string;
    customerName: string;
    source: string;
    eCommerceRefId: string;
    traderId: Guid;
    lines: Array<OrderLine> = [];
    log: string;

    herdNo: string;
    datamarsRequestId: Guid;
    aimsRequestId: string;

    deliveryTraceId: string;
}


export class OrderLine {
    id: string;
    herdNo: string;
    productId: string;
    name: string;
    qty: number;
    isTagRequired: boolean;
    tag: string;
    deptTagTypeId: number;
    deptProcessed: boolean;
    deptOrderNum: string;
}

export class DataMarsOrder {
    id: Guid;
    number: string;
    statusText: string;
}