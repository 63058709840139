import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { Species } from 'src/app/shared/models/species.model';
import { SpeciesService } from 'src/app/shared/services/species.service';

@Component({
  selector: 'app-species',
  templateUrl: './species.component.html',
  styleUrls: ['./species.component.scss']
})
export class SpeciesComponent implements OnInit, OnDestroy {


  formValid = false;
  species = new Species();
  private sub: Subscription;
  private dataSub: Subscription;

  constructor(private speciesService: SpeciesService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      const id = params.id;
      if (id) {
          this.loadData(Guid.parse(id));
      }
    });
  }

    onFieldDataChanged(e) {
      this.formValid = e.component.validate().isValid;
    }
  
    loadData(id: Guid) {
      if (this.dataSub) {
        this.dataSub.unsubscribe();
      }
      this.dataSub = this.speciesService.getSingle(id)
        .subscribe(x => {
          this.species = x;
        });
    }
  
    saveSpecies(e: Species) {
      this.speciesService.save(e).subscribe(x => {
        if (x) {
          this.router.navigate(['/data/species-list']);
        }
      });
    }
  
    ngOnDestroy() {
      if (this.sub) {
        this.sub.unsubscribe();
      }
      if (this.dataSub) {
        this.dataSub.unsubscribe();
      }
    }

}
