import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import notify from 'devextreme/ui/notify';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap(event => {

        },
          err => {
            if (req.url.includes('/log')) {
              return next.handle(req);
            }
            this.processError(req, err);
          }
        ));

  }

  private processError(request: HttpRequest<any>, error: any) {
    if (error instanceof HttpErrorResponse) {
      const message: string = 'Unauthorized. Please login with valid user'
      switch (error.status) {
        case 499: // custom status. internal redirect instructed by server
          console.log(`received 499.  investigate: ${error.error}`);
          //this.router.navigate([error.error]);
          break;
        case 401:
          notify(message, 'error');
          break;
        case 403:
          notify(message, 'error');
          break;
        default:
          //this.logger.logWarning(`On requesting url ${request.urlWithParams} HttpInterceptor received Error response with Status '${error.status}' and Message: '${error.message}'`)
      }
    }
  }

}
