import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserModel } from '../models/userInfo.model';

@Injectable()
export class AppInfoService {
  constructor(private authService: MsalService) { }

  public get title() {
    return 'Agri Tags';
  }

  public get currentYear() {
    return new Date().getFullYear();
  }


  public currentUser(): UserModel | null {
    var users = this.authService.instance.getAllAccounts();
    if (!users || users.length == 0) return null;

    var user: UserModel = {
      email: users[0].username,
      name: users[0].name,
      tennantId: users[0].tenantId
    };

    return user;
  }
}
