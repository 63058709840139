<h2 class="content-block">AIMS Errors</h2>
<div class="content-block">
  <div class="dx-card responsive-paddings">
    <div class="row">
        <div class="col-12 text-center">
            <dx-data-grid id="gridCustomers" keyExpr="id" (onRowRemoving)="onRowRemoving($event)"
                (onInitNewRow)="onInitNewRow($event)" [dataSource]="records" [allowColumnResizing]="true">

                <dxo-editing [allowUpdating]="false" [allowAdding]="true" [allowDeleting]="true" mode="form">
                </dxo-editing>
                <dxo-export [enabled]="true" fileName="xtrace-departments"
                    [allowExportSelectedData]="true"></dxo-export>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>

                <dxi-column caption="Code" cellTemplate="codeTemplate" width="100" ></dxi-column>
                <dxi-column dataField="description"></dxi-column>
                <dxi-column dataField="active"></dxi-column>
                <div *dxTemplate="let d of 'codeTemplate'">
                    <strong><a
                            [routerLink]="['/aims/error', d.data.id]">{{d.data.code }}</a></strong>
                </div>
            </dx-data-grid>
        </div>
    </div>

</div>
</div>