import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from './base-data.service';
import { Product } from '../models/product.model';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class ProductDataService  extends BaseDataService {

  constructor(private http: HttpClient) {
    super();
  }


  getAll(): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.apiUrl}/product`);
   }

   getSingle(id: Guid): Observable<Product> {
    return this.http.get<Product>(`${this.apiUrl}/product/${id}`);
   }

  save(model: Product): Observable<Product> {
    if (model.id) {
      return this.http.post<Product>(`${this.apiUrl}/product`, model);
    } else {
      return this.http.put<Product>(`${this.apiUrl}/product`, model);
    }
  }

  delete(id: Guid): Observable<any> {
    return this.http.delete(`${this.apiUrl}/product/${id}`);
  }
}
