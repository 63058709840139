import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";

export const environment = {
  production: true,
  apiUrl: 'https://agritags.agrihealth.ie',
  hasEpicorAccess: false,
  msalConfig: {
    auth: {
      clientId: 'c379a38c-68b7-4296-95bf-7f7b083b7a4c', // This is the ONLY mandatory field that you need to supply.
      authority: 'https://login.microsoftonline.com/fe5f271d-6b7f-4c2f-8deb-4c28de440290',
      redirectUri: 'https://agritags.agrihealth.ie',
      scope: 'api://fa7472db-9a5f-4236-b84c-c06546b0ea2e/User.Auth'
      // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          console.log(message);
        },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  }
};
